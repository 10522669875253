import React, { CSSProperties, useEffect, useMemo, useState } from 'react';

import { Box, Divider, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { NotesRounded, RestoreRounded, LocalShippingOutlined, DescriptionOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as ScienceOutlined } from 'images/product-composition-icon.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Markup } from 'react-render-markup';
import moment from 'moment';

import { useDebounce } from 'use-debounce';
import { useDispatch } from 'react-redux';
import { Path } from 'history';
import { Dispatch } from 'redux';

import Layout from 'components/views/Layout';
import AppNav from 'components/common/AppNav';
import ShowIf from 'components/views/ShowIf';

import { FavoriteButton } from 'components/common/FavoriteButton';
import { ProductCardRibbon } from 'components/common/ProductCard/components/product-card-ribbon';
import { ProductCardTierDiscount } from 'components/common/ProductCard/components/product-card-tier-discount';
import { ProductCardQuantityBox } from 'components/common/ProductCard/components/product-card-quantity-box';
import { SimilarProductsButton } from 'components/common/SimilarProductsButton';
import { StockOutDialog } from 'components/common/ProductCard/components/stock-out-dialog';
import { LoyaltyPointReward } from 'components/common/LoyaltyPointReward';
import { SnackbarCartComponent } from 'components/common/snackbar-cart';
import qs from 'query-string';
import { InitialSuggestionsEnum, useHelpDialog, usePageIdentity } from 'hooks';
import { useTierPricing } from 'hooks/useTierPricing';
import { useStockOut } from 'hooks/useStockOut';

import { toCurrency, toPercent } from 'utils/Format';
import { TranslateProps } from 'utils/Localization';
import { getMarketId } from 'utils/MarketConfig';
import {
  trackProductDetailPage,
  ProductSimpleSearchLocationProps,
  SegmentPropsBuilder,
  BasePageTracker,
  pageTracker,
  SearchSectionState,
} from 'utils/Analytics/Segment';
import {
  trackViewItemDetail as causalFoundryTrackViewItemDetail,
  trackViewItem as causalFoundryTrackViewItem,
} from 'utils/Analytics/CausalFoundry';
import { getMaxPurchaseLimitsFromProduct } from 'utils/Product/product.util';
import { store } from 'store';
import { clearProductListing, setListingPageReferer } from 'store/reducers/ProductListing/actions';
import { clearCampaignDetail } from 'store/reducers/Campaign/actions';
import { DOCUMENT_REQUIREMENT_PREKURSOR_FLAG } from 'services';

import { Tour } from 'components/common/Tour';
import { Widget } from 'components/common/Widget';
import { Accordion, SearchMenuSuggestions } from 'components/common';

import { LIFECYCLE, STATUS } from 'react-joyride';
import { OrgMembershipTypeEnum } from 'store/reducers/Auth';

import { ProductContext } from 'components/common/ProductCard/ProductCard.context';
import { routeKey } from 'utils/route.utils';
import { BRACKET_QS_OPTIONS } from 'utils/constants';
import { clearProductDetailImpression } from 'store/reducers/ProductImpression/actions';
import * as colors from '../../styles/colors';

import { defaultWidgetConfig } from '../HomePage/style';

import { ProductImage, PromoCarousel } from './components';
import {
  BoldCaption,
  SubCaption,
  ProductPriceContainer,
  ProductSellingPrice,
  ProductDetailIcon,
  ExpiryDateIcon,
  ProductDiscountedPrice,
  ProductDetailContainer,
  ProductDetailGrid,
  ProductDetailItemTitle,
  ProductDetailItem,
  IconTitleContainer,
  ProductInfoBody,
  ProductName,
  MaxQty,
  ProductPackaging,
  RelativeGridItem,
  ScrollableGridContainer,
  StickyFooterGridItem,
  TierContainer,
  PharmacyTierContainer,
  UnverifiedLockIcon,
  tierStyles,
  PharmacyTierLearnMore,
  SectionDivider,
  CartDivider,
} from './style';
import { StateProps, DispatchProps, ITabsProductDetail, ComponentProps } from './interface';
import {
  DetailPageDisplayType,
  defaultTabs,
  getProductCategory,
  getProductSubCategories,
  initialProduct,
  TabReferenceId,
} from './utils';
import { Basket } from '../MainPage/components/basket';
import { CartButton } from './components/CartButton/component';
import { ProductLoyaltyPoint } from './components/ProductLoyaltyPoint';
import { RequiredDocument } from './components/RequiredDocument';
import { getSearchProps } from '../ProductListingPage/utils/ProductlistingPage.util';
import { FavoriteLinkIcon } from '../MainPage/components/favorite-link';
import { useSimilarProductDetail } from './hooks/useSimilarProductDetail.hook';

type Props = StateProps & DispatchProps & TranslateProps & ComponentProps;

export const ProductDetailPage: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const marketId = getMarketId();
  const dispatch = useDispatch();
  const { goBack, push } = useHistory();

  const { toggleHelpDialog } = useHelpDialog();

  const { id: productIdQs } = useParams<{ id?: string }>();
  const [value, setValue] = useState(0);
  const [mappingTabs, setMappingTabs] = useState<ITabsProductDetail[]>(defaultTabs);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);

  const [delayValue] = useDebounce(value, 200);

  const queryString = new URLSearchParams(window.location.search);
  const marketingId = queryString.get('marketing_id') ?? '';

  const { config, campaign, auth } = store.getState();
  const { coreUser } = auth || {};
  const location = useLocation<ProductSimpleSearchLocationProps & { isModalOpen?: boolean }>();
  const productSearchState = usePageIdentity();

  const {
    t,
    isLoading,
    productDetail: product = initialProduct,
    cart,
    fetchProductDetail,
    setRecentlyViewedProducts,
    addOrUpdateProductQuantity,
    isProductUpdated,
    setHasSimilarProducts,
    hasSimilarProducts,
    displayType = DetailPageDisplayType.ROUTE,
    productId,
    closeModal,
    snackbarCart,
    setCloseSnackbarCart,
    baseCloseModal,
    setRecentlyViewedSectionProducts,
  } = props;

  const currentProductId = displayType === DetailPageDisplayType.MODAL ? productId : productIdQs;

  const {
    itemCount,
    color,
    changeColor,
    isPromoApplied,
    activePrice,
    activeDiscount,
    activeDiscountedPrice,
    isTierDiscountAvailable,
  } = useTierPricing(product);

  const {
    isOpen,
    toggle: toggleDialog,
    requestAvailability,
    removeAvailabilityRequest,
    isStockOut,
    availabilityRequested,
  } = useStockOut(product);

  const { similarProducts: similarProductList, skuCode, similarProductMarketing } = useSimilarProductDetail(product);

  const locationParsed = qs.parse(location.search, BRACKET_QS_OPTIONS) as ProductSimpleSearchLocationProps & {
    isModalOpen?: boolean;
  };
  const trackProductDetailPagePayload = SegmentPropsBuilder.buildSimpleAnalyticsProps(
    Number(currentProductId),
    isStockOut,
    locationParsed,
    campaign,
    location?.state as SearchSectionState,
    marketingId,
  );

  const dividerOffset = itemCount ? 1.16 : 1.3;

  const { indexNumber } = (location?.state as unknown as { indexNumber?: number }) || {};

  useEffect(() => {
    if (currentProductId) {
      setRecentlyViewedProducts(Number(currentProductId));
    }
    const isSameProduct = product && product.id === Number(currentProductId);
    const isShouldBeUpdate = isProductUpdated && displayType !== DetailPageDisplayType.MODAL;

    setValue(0);

    if (!isSameProduct || isShouldBeUpdate) {
      fetchProductDetail(Number(currentProductId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProductId]);

  useEffect(() => {
    setCloseSnackbarCart();

    if (product && product.id === Number(currentProductId)) {
      causalFoundryTrackViewItem(product, { promo_id: marketingId });

      trackProductDetailPage(trackProductDetailPagePayload);
      setRecentlyViewedSectionProducts(product);
    }

    return () => {
      setHasSimilarProducts(false);
      dispatch(clearProductDetailImpression());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToAccountBenefits = useMemo(() => () => push(`/${marketId}/main/accounts/benefits`), []);
  const goToCart = useMemo(
    () => (page: string) => (): void => {
      push({
        pathname: page,
        search: '',
        state: { source: 'search_box' },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const showSellingPrice = activePrice > activeDiscountedPrice;
  const isPromoAvailable = product.tier_discount.length > 0;
  const availablePromotions = product.tier_discount.map((tier) => ({
    discount: `${t('discount')} ${toPercent(tier.discount_rate, 2)}%`,
    minimum: `${t('minimum')} ${tier.min}`,
  }));

  const [favorite, setFavorite] = useState({
    id: product.favorite_product_id,
    favorited: !!product.favorited,
  });

  useEffect(() => {
    setFavorite({
      id: product.favorite_product_id,
      favorited: !!product.favorited,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    const updatedTabs = defaultTabs.map((tab) => {
      const element = document.getElementById(tab.id);
      const elementPosition = element?.offsetTop || 0;
      const elementHeight = element?.clientHeight || 0;

      const shouldHideSimilarProducts = !hasSimilarProducts && tab.id === TabReferenceId.RECOMMENDATION;
      return {
        id: tab.id,
        offsetPosition: elementPosition,
        elementHeight,
        hidden: shouldHideSimilarProducts,
      };
    });
    setMappingTabs(updatedTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSimilarProducts, product]);

  const navigateToHomepage = (push: (path: Path, state?: any) => void, dispatch: Dispatch<any>) => {
    push(routeKey('main/home'));
    dispatch(clearCampaignDetail());
  };

  const handleBackButton = (): void => {
    const isModal = displayType === DetailPageDisplayType.MODAL;
    const isLoyaltyPage = pageTracker.currentPage === 'pt_view_loyalty_products';
    const isProductListingType = locationParsed?.marketing_type === 'product-listing';
    const isNeedToHomepage = (store.getState().global?.backStackLength || 0) <= 0;

    if (isModal && closeModal) {
      closeModal();
      if (isNeedToHomepage) {
        navigateToHomepage(push, dispatch);
      }
    } else if (isNeedToHomepage) {
      navigateToHomepage(push, dispatch);
    } else if (isLoyaltyPage && isProductListingType) {
      push({
        pathname: `/${marketId}/marketing/${marketingId}/product-listing`,
        search: new URLSearchParams(`goBackTo=${productSearchState.goBackTo}`).toString(),
      });
    } else {
      goBack();
    }

    if (!isModal) {
      dispatch(setListingPageReferer(BasePageTracker.PRODUCT_DETAIL));
    }
  };

  const [tourMode, setTourMode] = useState(false);

  const handleTour = (data: any): void => {
    const { lifecycle, step, status } = data;
    if (status === STATUS.FINISHED) {
      setTourMode(false);
    }

    if (lifecycle !== LIFECYCLE.READY || step.target === 'body') return;

    const scrollable = document.querySelector<HTMLElement>('#tour__scroll');

    if (scrollable) {
      if (step.target === '#tour__tierPricing') {
        scrollable.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      } else {
        setTourMode(true);
        const target = document.querySelector<HTMLElement>(step.target);
        if (target) {
          const elementPosition = target.offsetTop;
          const containerPosition = target.parentElement?.offsetTop || 0;
          const offsetPosition = (containerPosition + elementPosition) / dividerOffset;

          scrollable.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }
  };

  const displayLoyaltyPointReward = (): boolean => {
    const cartInfo = cart?.items[product.id];
    const isAddedToCart = cartInfo?.count > 0 || false;
    const points = product.loyalty_point?.points || 0;
    return !isAddedToCart && points > 0;
  };

  const isPharmacyTierEnabled = config.market.featureConfigs?.pharmacyTier === 'enabled';
  const isUnpurchasable = product?.is_unpurchasable;

  const goToSimilarProductList = (): void => {
    dispatch(clearProductListing());
    push(`/${marketId}/similar-products/${product?.sku_code}`, { initial_page: BasePageTracker.PRODUCT_DETAIL });
  };

  const onAccordionClick = (): void => {
    causalFoundryTrackViewItemDetail(product, { promo_id: marketingId });
  };

  const handleChange = (_, newValue: number): void => {
    setValue(newValue);
    const element = document.getElementById(defaultTabs[newValue].id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  const onScrollElement = (e): void => {
    const element1 = mappingTabs[0];
    const element2 = mappingTabs[1];

    const endFirstElement = element1.offsetPosition + element1.elementHeight / dividerOffset;
    const endSecondElement = (element2.offsetPosition + element2.elementHeight) / dividerOffset;

    const offsetScroll = e.target.scrollTop;
    const parentElementheight = (e.target.clientHeight - 100) / 4;
    const currentScroll = offsetScroll + parentElementheight;
    const endOfScroll = offsetScroll + e.target.clientHeight;
    const bottomOffset = e.target.scrollHeight;
    let activeTabIndex = value;

    if (currentScroll < endFirstElement) {
      activeTabIndex = 0;
    } else if (currentScroll > endFirstElement && currentScroll < endSecondElement) {
      activeTabIndex = 1;
    } else if (currentScroll > endSecondElement) {
      activeTabIndex = 2;
    }

    // End of element
    if (bottomOffset - endOfScroll === 0) {
      activeTabIndex = hasSimilarProducts ? 3 : 2;
    }

    if (activeTabIndex !== value) {
      setValue(activeTabIndex);
    }
  };

  const tabStyles: CSSProperties = { fontSize: 11.5, textTransform: 'none', color: 'green', whiteSpace: 'nowrap' };

  const searchProps = getSearchProps(BasePageTracker.GENERAL_SEARCH, product.name, {});
  const goToWishlist = (): void => push(routeKey('wishlist_favorites'));
  const { productMaxLimitEnabled, productMaxMonthlyLimitEnabled, productMaxDailyLimit, productMaxMonthlyLimit } =
    getMaxPurchaseLimitsFromProduct(product);

  return (
    <Layout full background={colors.WHITE}>
      <AppNav removeMenuOption theme="light">
        <Basket onClick={goToCart} cartCount={cart.total} />
        {showDrawer && <FavoriteLinkIcon id="tour__wishlist" onClick={goToWishlist} />}
        <SearchMenuSuggestions
          onClick={() => setShowDrawer(true)}
          onClose={() => setShowDrawer(false)}
          onBack={handleBackButton}
          placeholder={product.name}
          searchResultsPage={`/${marketId}/search`}
          initialSuggestions={[
            InitialSuggestionsEnum.CAMPAIGN_BASED,
            InitialSuggestionsEnum.RECENTLY_SEARCHED,
            InitialSuggestionsEnum.RECENTLY_VIEWED,
          ]}
          autoFocus={false}
          searchUrl="products/search"
          onSearch={baseCloseModal}
          autoFill={false}
          detailPageId={Number(currentProductId)}
          {...searchProps}
        />
      </AppNav>

      <ShowIf condition={isLoading}>
        <Skeleton variant="rect" height="50%" />
        <br />
        <Divider />
        <Typography variant="h5">
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </Typography>
      </ShowIf>

      <ShowIf condition={!isLoading}>
        <ScrollableGridContainer id="tour__scroll" onScroll={onScrollElement}>
          <Box sx={{ position: 'sticky', top: -1, height: 44, zIndex: 9, bgcolor: '#fff' }}>
            <Tabs
              value={delayValue}
              onChange={(e, nv) => handleChange(e, nv)}
              variant="fullWidth"
              TabIndicatorProps={{
                title: 'indicator',
                style: { zIndex: 1, top: 42 },
              }}
              centered
              indicatorColor="primary"
            >
              {mappingTabs
                .filter((item) => !item.hidden)
                .map((item) => (
                  <Tab key={item.id} label={t(item.id)} style={tabStyles} />
                ))}
            </Tabs>
          </Box>
          <RelativeGridItem padding="0" id={TabReferenceId.SUMMARY}>
            <ProductImage product={product} />
            <ProductCardRibbon
              discount={activeDiscount}
              color={color.secondary}
              isStockOut={isStockOut}
              position={{
                left: 0,
                top: '8px',
              }}
              isUnpurchasable={isUnpurchasable}
            />
          </RelativeGridItem>

          {isPharmacyTierEnabled && isUnpurchasable && (
            <RelativeGridItem>
              <PharmacyTierContainer>
                <UnverifiedLockIcon />
                <Typography>
                  {product?.flag === DOCUMENT_REQUIREMENT_PREKURSOR_FLAG &&
                  !coreUser?.membership_status?.includes(OrgMembershipTypeEnum.sipnap)
                    ? t('missingSipnap')
                    : t('unpurchasableInfo')}
                </Typography>
                <PharmacyTierLearnMore onClick={goToAccountBenefits}>{`${t('learnMore')} >`}</PharmacyTierLearnMore>
              </PharmacyTierContainer>
            </RelativeGridItem>
          )}

          <RelativeGridItem>
            <ProductName variant="subtitle2">
              {product.name}
              <ShowIf condition={productMaxLimitEnabled || productMaxMonthlyLimitEnabled}>
                <MaxQty>
                  &nbsp;
                  {t(productMaxLimitEnabled ? 'maxDailyQtyLimit' : 'maxMonthlyQtyLimit', {
                    value: productMaxLimitEnabled ? productMaxDailyLimit : productMaxMonthlyLimit,
                  })}
                </MaxQty>
              </ShowIf>
            </ProductName>
            <ProductPackaging>{product.packaging}</ProductPackaging>
            <br />

            <ProductPriceContainer>
              <ProductDiscountedPrice>{toCurrency(activeDiscountedPrice)}</ProductDiscountedPrice>
              <ShowIf condition={showSellingPrice}>
                <ProductSellingPrice>{toCurrency(activePrice)}</ProductSellingPrice>
              </ShowIf>
            </ProductPriceContainer>

            <FavoriteButton
              location={{ top: '1rem', right: '1rem' }}
              product={{ ...product, favorite_product_id: favorite.id, favorited: favorite.favorited }}
              t={t}
            />
          </RelativeGridItem>
          <ShowIf condition={!!product.flag}>
            <RelativeGridItem>
              <RequiredDocument product={product} />
            </RelativeGridItem>
          </ShowIf>
          <ShowIf condition={!!product.loyalty_point}>
            <RelativeGridItem>
              <ProductLoyaltyPoint point={product.loyalty_point} />
            </RelativeGridItem>
          </ShowIf>
          <ShowIf condition={isPromoAvailable}>
            <RelativeGridItem id="tour__prod_promotion">
              <BoldCaption>{t('availablePromo')}</BoldCaption>
              <PromoCarousel promotions={availablePromotions} />
            </RelativeGridItem>
          </ShowIf>

          <SectionDivider />

          <ShowIf
            condition={
              !!product?.nearest_expiry_date || !!product.manufacturer || !!getProductCategory(product.categories)
            }
          >
            <div id={TabReferenceId.DETAILS}>
              <RelativeGridItem>
                <Accordion
                  title="Detail"
                  isDropdown={false}
                  advanceConfig={{
                    titleElement: (
                      <IconTitleContainer>
                        <ProductDetailIcon as={NotesRounded} />
                        <BoldCaption>{t('detail')}</BoldCaption>
                      </IconTitleContainer>
                    ),
                    contentElement: (
                      <ProductDetailContainer>
                        <ProductDetailGrid>
                          <ShowIf condition={!!product?.nearest_expiry_date}>
                            <ProductDetailItemTitle>{t('nearestExpiryDateTitle')}</ProductDetailItemTitle>
                            <ProductDetailItem style={{ color: colors.ORANGE, fontWeight: 700 }}>
                              <>
                                <ExpiryDateIcon />
                                {moment(product?.nearest_expiry_date, 'YYYY-MM-DD').format('MMM DD YYYY') ?? '-'}
                              </>
                            </ProductDetailItem>
                          </ShowIf>
                        </ProductDetailGrid>
                        <ProductDetailGrid>
                          <ShowIf condition={!!product?.manufacturer}>
                            <ProductDetailItemTitle>{t('manufacturer')}</ProductDetailItemTitle>
                            <ProductDetailItem>{product.manufacturer}</ProductDetailItem>
                          </ShowIf>
                        </ProductDetailGrid>
                        <ProductDetailGrid>
                          <ShowIf condition={!!getProductCategory(product.categories)}>
                            <ProductDetailItemTitle style={{ paddingBottom: '0px' }}>
                              {t('category')}
                            </ProductDetailItemTitle>
                            <ProductDetailItem style={{ paddingBottom: '0px' }}>
                              {getProductCategory(product.categories) ? getProductCategory(product.categories) : '-'}
                            </ProductDetailItem>
                          </ShowIf>
                        </ProductDetailGrid>
                      </ProductDetailContainer>
                    ),
                  }}
                />
              </RelativeGridItem>
            </div>
            <SectionDivider />
          </ShowIf>

          <ShowIf
            condition={
              !!product.description || !!product?.core_molecule || !!getProductSubCategories(product?.categories)
            }
          >
            <RelativeGridItem>
              <Accordion
                title={t('description')}
                isOpen={false}
                onClick={onAccordionClick}
                advanceConfig={{
                  titleElement: (
                    <IconTitleContainer>
                      <ProductDetailIcon as={DescriptionOutlined} />
                      <BoldCaption>{t('description')}</BoldCaption>
                    </IconTitleContainer>
                  ),
                  contentElement: (
                    <>
                      <ShowIf condition={!!product?.description}>
                        <ProductInfoBody>
                          <Markup markup={product?.description || ''} />
                        </ProductInfoBody>
                      </ShowIf>
                      <ShowIf condition={!!getProductSubCategories(product?.categories)}>
                        <SubCaption>{t('atcClassification')}</SubCaption>
                        <ProductInfoBody>{getProductSubCategories(product?.categories)}</ProductInfoBody>
                      </ShowIf>
                      <ShowIf condition={!!product?.core_molecule}>
                        <SubCaption>{t('coreMolecule')}</SubCaption>
                        <ProductInfoBody>{product?.core_molecule}</ProductInfoBody>
                      </ShowIf>
                    </>
                  ),
                }}
              />
            </RelativeGridItem>
            <SectionDivider />
          </ShowIf>

          <ShowIf condition={!!product.molecule}>
            <RelativeGridItem>
              <Accordion
                title={t('composition')}
                isOpen={false}
                onClick={onAccordionClick}
                advanceConfig={{
                  titleElement: (
                    <IconTitleContainer>
                      <ProductDetailIcon as={ScienceOutlined} />
                      <BoldCaption>{t('composition')}</BoldCaption>
                    </IconTitleContainer>
                  ),
                  contentElement: <ProductInfoBody>{product?.molecule}</ProductInfoBody>,
                }}
              />
            </RelativeGridItem>
            <SectionDivider />
          </ShowIf>

          <div id={TabReferenceId.SHIPPING}>
            <RelativeGridItem id="tour__prod_shipping">
              <Accordion
                title={t('shipmentTitle')}
                isDropdown={false}
                advanceConfig={{
                  titleElement: (
                    <IconTitleContainer>
                      <ProductDetailIcon as={LocalShippingOutlined} />
                      <BoldCaption>{t('shipmentTitle')}</BoldCaption>
                    </IconTitleContainer>
                  ),
                  contentElement: <ProductInfoBody>{t('shipmentContent1')}</ProductInfoBody>,
                }}
              />
            </RelativeGridItem>
          </div>

          <SectionDivider />

          <RelativeGridItem>
            <Accordion
              title={t('returnTitle')}
              isDropdown={false}
              advanceConfig={{
                titleElement: (
                  <IconTitleContainer>
                    <ProductDetailIcon as={RestoreRounded} />
                    <BoldCaption>{t('returnTitle')}</BoldCaption>
                  </IconTitleContainer>
                ),
                contentElement: (
                  <ProductInfoBody>
                    <Markup markup={product?.return_policy || t('shipmentContent2')} />
                  </ProductInfoBody>
                ),
              }}
            />
          </RelativeGridItem>

          <SectionDivider />

          <div id={TabReferenceId.RECOMMENDATION}>
            <ShowIf condition={!!similarProductMarketing}>
              <RelativeGridItem id={`tour__${similarProductMarketing?.type}`}>
                <Widget
                  isHomepage={false}
                  loading={isLoading}
                  marketing={similarProductMarketing}
                  onItemClick={toggleHelpDialog}
                  defaultStyling={defaultWidgetConfig.styling}
                  productImpressionPage={BasePageTracker.PRODUCT_DETAIL}
                  params={{ sku_code: skuCode }}
                  data={similarProductList}
                />
              </RelativeGridItem>
            </ShowIf>
          </div>

          <StickyFooterGridItem padding="0">
            <ShowIf condition={snackbarCart.open}>
              <SnackbarCartComponent
                open={!!snackbarCart.open}
                productId={snackbarCart.productId}
                itemCount={snackbarCart.itemCount}
                purchaseableQuantity={snackbarCart.maxPurchaseLimit.purchaseableQuantity}
                hasPurchased={snackbarCart.maxPurchaseLimit.hasPurchased}
              />
            </ShowIf>
            <ProductContext.Provider value={{ indexNumber }}>
              <div className="container">
                <CartButton
                  disabled={!product.net_price}
                  isStockOut={isStockOut}
                  product={product}
                  cart={cart}
                  availability={{
                    request: requestAvailability,
                    remove: removeAvailabilityRequest,
                    requested: availabilityRequested,
                  }}
                  similarProductList={similarProductList}
                  isLoading={isLoading}
                >
                  {displayLoyaltyPointReward() ? (
                    <Grid container alignItems="center" justify="space-between" spacing={1}>
                      <ShowIf condition={!!similarProductList && similarProductList.length > 0}>
                        <Grid item>
                          <SimilarProductsButton text={t('similarProducts')} onClick={goToSimilarProductList} />
                        </Grid>
                        <CartDivider
                          orientation="vertical"
                          flexItem
                          style={{ marginTop: '5px', marginBottom: '5px', marginRight: '10px' }}
                        />
                      </ShowIf>
                      <Grid item xs={3}>
                        <LoyaltyPointReward
                          points={product.loyalty_point?.points}
                          rewards={product.loyalty_point?.rewards}
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <ProductCardQuantityBox
                          text={t('addToCart')}
                          fullWidth
                          color={color}
                          product={product}
                          changeColor={changeColor}
                          isLoyaltyPointsShown
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <ProductCardQuantityBox
                      text={t('addToCart')}
                      fullWidth
                      color={color}
                      product={product}
                      changeColor={changeColor}
                      isLoyaltyPointsShown={false}
                      similarProductList={similarProductList}
                    />
                  )}
                </CartButton>

                <ShowIf condition={!isStockOut}>
                  <TierContainer tour={tourMode}>
                    <ProductCardTierDiscount
                      product={product}
                      color={color}
                      itemCount={itemCount}
                      isPromoApplied={isPromoApplied}
                      activeDiscount={activeDiscount}
                      isTierDiscountAvailable={isTierDiscountAvailable}
                      addOrUpdateProductQuantity={addOrUpdateProductQuantity}
                      styles={tierStyles}
                      autoExpand={tourMode}
                    />
                  </TierContainer>
                </ShowIf>
              </div>
            </ProductContext.Provider>
          </StickyFooterGridItem>
        </ScrollableGridContainer>

        <StockOutDialog isOpen={isOpen} toggle={toggleDialog} />

        <Tour
          module="product_detail_page"
          welcomeTitle="tour.productDetailPage.welcomeTitle"
          welcomeMessage="tour.productDetailPage.welcomeMessage"
          disableScrolling
          onCallback={handleTour}
          styles={{
            buttonClose: {
              display: 'none',
            },
          }}
        />
      </ShowIf>
    </Layout>
  );
};
